<script setup lang="ts">
import type { SbBlokData } from '@storyblok/js';

interface IProps {
    blok: LandingBlockContainerBlokInterface;
    globalArticleCopy: GlobalArticleCopyInterface;
}
const props = defineProps<IProps>();

const hasSubtitle = computed(() => doesRichTextContainText(props.blok.subtitle));

// default to center (for backwards compatibility) if no value is defined
const headingAlignmentClass = computed(() =>
    props.blok.headingAlignment ? `text-${props.blok.headingAlignment}` : 'text-center',
);
</script>

<template>
    <page-block-wrapper
        v-editable="blok"
        :background="blok.background"
        :disable-bottom-margin="blok.disableBottomMargin">
        <es-container>
            <!-- eslint-disable-next-line vuejs-accessibility/heading-has-content -->
            <h2
                class="h1 text-primary"
                :class="{
                    'mb-200': !hasSubtitle,
                    [headingAlignmentClass]: true,
                }">
                <rich-text-component :content="blok.heading" />
            </h2>
            <p
                v-if="hasSubtitle"
                class="text-center mb-200">
                <rich-text-component :content="blok.subtitle" />
            </p>
        </es-container>
        <storyblok-component
            v-for="childBlok in blok.content"
            :key="childBlok._uid"
            :blok="childBlok as unknown as SbBlokData"
            :global-article-copy="globalArticleCopy" />
        <es-container>
            <div
                v-if="blok.ctas?.length"
                class="align-items-center d-flex flex-column mt-200">
                <storyblok-component
                    v-for="childBlok in blok.ctas"
                    :key="childBlok._uid"
                    :blok="childBlok as unknown as SbBlokData" />
            </div>
        </es-container>
    </page-block-wrapper>
</template>
